import React from 'react'
import RatioBox from '../../../../../components/UI/RatioBox'

const VideoPreview = (props: { src: string }) => {
  const url = new URL(props.src)
  
  if (url.host === 'vimeo.com') {
    const params = new URLSearchParams(url.search)
    const vimeoId = url.pathname.split('/')[1]
    const hash = url.pathname.split('/')[2]
    return <RatioBox ratio='16:9'>
      <iframe title="vimeo-player" src={`https://player.vimeo.com/video/${vimeoId}?h=${hash}`} width="100%"  height="100%" frameBorder="0" allowFullScreen />
    </RatioBox>
  }
  return null
    
}

export default VideoPreview
