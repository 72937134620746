import { navigate } from 'gatsby';
import 'twin.macro'
import { IconButton, Pencil, Plus, TrashWhite } from 'nzk-react-components';
import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import useData from '../data/AssignmentTemplate/useData';
import Loader from '../../../UI/Loader';
import useConfirmModal from '../../../../hooks/useConfirmModal';
import RatioBox from '../../../UI/RatioBox';
import VideoPreview from '../AssignmentTemplateCreatePage/components/VideoPreview';

const Wrapper = styled.div`
  padding-bottom: 40px;
`

const Header = styled.div`
  display: flex;
  > * { margin-right: 10px; }
  > :last-child { margin-right: 0; }
`

const AssignmentTemplatePage = (props: { templateId: string }) => {
  const [template, setTemplate] = useState<any>(null)
  const [loading, setLoading] = useState(true)
  const { fetchAssignmentTemplate, duplicateAssignmentTemplate, deleteAssignmentTemplate } = useData()

  useEffect(() => {
    const fetch = async () => {
      setLoading(true)
      const data = await fetchAssignmentTemplate(props.templateId)
      setTemplate(data)
      setLoading(false)
    }
    fetch()
  }, [props.templateId])

  const onDuplicate = async (templateId: string) => {
    const template = await duplicateAssignmentTemplate(templateId)
    navigate(`/star-tutoring/assignment-templates/${template._id}`)
  }

  const onDelete = async (templateId: string) => {
    confirmDelete(templateId)
  }

  const confirmDelete = useConfirmModal({
    title: "Are you sure?",
    message:
      "This template will be gone forever and removed from every schedule it's already been sent to and all lineups it's in.",
    onConfirm: async (templateId: string) => {
      await deleteAssignmentTemplate(templateId)
      navigate('/star-tutoring/assignment-templates?refetch=true')
    },
    onCancel: () => {},
  })

  if (loading) return <Loader />
  return <Wrapper>
    <Header>
      <IconButton icon={<Pencil />} size='small' theme='orange' onClick={() => navigate(`/star-tutoring/assignment-templates/${props.templateId}/edit`)}>Edit</IconButton>
      <IconButton icon={<Plus />} theme='purple' size='small' onClick={() => onDuplicate(props.templateId)}>Duplicate</IconButton>
      <div tw='ml-auto'>
        <IconButton icon={<TrashWhite />} theme='red' size='x-small' onClick={() => onDelete(props.templateId)}>Delete</IconButton>
      </div>
    </Header>
    { !loading && template && <>
      <h1 tw='text-center mt-3'>{template.title}</h1>
      <div tw='text-center mt-[-20px] mb-5'>{template._id}</div>
      <div>
        <div tw='bg-black/5 p-5 rounded-md' dangerouslySetInnerHTML={{ __html: template.description }} />
      </div>
      <div tw='mt-3 grid grid-cols-2 grid-gap[10px]'>
        <div tw='w-full'>
          <div tw='bg-black/5 p-5 rounded-md mb-3'>
            <h2>{template.writingPrompt.title}</h2>
            <div tw='text-sm' dangerouslySetInnerHTML={{ __html: template.writingPrompt.description }} />
          </div>
          <div tw='mb-2'><span tw='font-bold mr-1'>Type:</span>{template.writingPrompt.writingType || 'None'}</div>
          <div tw='font-weight[bold] mb-2'>Objectives</div>
          { template.writingPrompt.targets && <ul>
            {
              template.writingPrompt.targets.map((t, i) => <li key={i}>
                <div dangerouslySetInnerHTML={{ __html: t.title }} />
              </li>)
            }
          </ul> }
          <div tw='font-weight[bold] mb-2'>Words</div>
          { template.writingPrompt.wordBank && <ul>
            {
              template.writingPrompt.wordBank.map((w, i) => <li key={i}>
                {w.title}
              </li>)
            }
          </ul> }
          <div><span tw='font-bold mr-1'>Min words:</span>{template.writingPrompt.minWordCount || 'None'}</div>
          <div><span tw='font-bold mr-1'>Max words:</span>{template.writingPrompt.maxWordCount || 'None'}</div>
        </div>
        <div tw='w-full all-child:mb-2'>
          { template.writingPrompt.image && <div>
            <RatioBox ratio='16:9'>
              <img tw='rounded-2xl object-fit[cover] object-position[center]' src={template.writingPrompt.image} height='100%' width='100%' />
            </RatioBox>
          </div> }
          { template.writingPrompt.video && <div tw='rounded-2xl overflow-hidden'>
            <RatioBox ratio='16:9'>
              <VideoPreview src={template.writingPrompt.video} />
            </RatioBox>
          </div> }
        </div>
      </div>
    </> }
  </Wrapper>
}

export default AssignmentTemplatePage
